<style lang="less" scoped>
</style>
<template>
  <div>
    {{ id }}
  </div>
</template>
<script>
export default {
  name: 'Demo',
  props: {
    // router 提供的 query 中，需有 id 属性
    id: {
      type: String,

      required: true
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (val) {
        console.log(val)
      }
    }
  }
}
</script>
